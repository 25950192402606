<template lang="pug">
    Dialog.log-agendamento.mx-6(header='Lista de agendamentos' :visible.sync="$parent.dialogLog" :modal="true" :style="{widht: '800px'}")
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='!list.length')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12(style="padding: .5em")
                        Panel.panel-list.ta-center
                            .ta-left
                                p <b>Horário:</b> {{ props.data.dt_auditoria_f }}
                                p <b>Medico Entrou: </b>
                                    span {{props.data.ie_medico_entrou_f}}
                                p <b>Paciente Entrou: </b>
                                    span {{props.data.ie_paciente_entrou_f}}
                                p <b>Chamada: </b>
                                    span.mtd-badge(:class="{ ea: props.data.ie_status_chamada == 'EA', \
                                                ps: props.data.ie_status_chamada == 'PS', \
                                                mc: props.data.ie_status_chamada == 'MC', \
                                                ex: props.data.ie_status_chamada == 'DE' && props.data.ie_status == 'EX', \
                                                de: props.data.ie_status_chamada == 'DE', \
                                                na: props.data.ie_status_chamada == '' || props.data.ie_status_chamada == null, \
                                                }")

            DataTable.datatable(:value="list")
                Column(headerStyle='width: 20%;' bodyStyle='text-align: center;' header='Horário')
                    template(#body='props')
                        p.ta-center {{props.data.dt_auditoria_f}}
                Column(headerStyle='width: 10%;' header='Medico Entrou')
                    template(#body='props')
                        p.ta-center {{props.data.ie_medico_entrou_f}}
                Column(headerStyle='width: 10%;' header='Paciente Entrou')
                    template(#body='props')
                        p.ta-center {{props.data.ie_paciente_entrou_f}}
                Column(headerStyle='width: 20%;' header='Chamada')
                        template(#body='props')
                            .label(:class="{ ea: props.data.ie_status_chamada == 'EA', \
                                                ps: props.data.ie_status_chamada == 'PS', \
                                                mc: props.data.ie_status_chamada == 'MC', \
                                                ex: props.data.ie_status_chamada == 'DE' && props.data.ie_status == 'EX', \
                                                de: props.data.ie_status_chamada == 'DE', \
                                                na: props.data.ie_status_chamada == '' || props.data.ie_status_chamada == null, \
                                                }")
                                span {{ props.data.ie_status_chamada_f }}
                Column(headerStyle='width: 20%;' header='Situação')
                    template(#body='props')
                        .cell(:class="{ ex: props.data.ie_status == 'EX', a: props.data.ie_status == 'A', l: props.data.ie_status == 'L' }")

</template>

<style lang="scss">
    .p-dialog {
        width: 900px;
    }
    .log-agendamento {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                width: 40px;
                height: 40px;
                margin: 0 auto;
                &.ex  {  background-color: #75d866; }
                &.a  { background-color: #ff9020; }
                &.l  { background-color: #e1f0f8; }
                &.r  { background-color: #f1e1f8; }
                &.na  { background-color: #f0f0f0; color:#666 }
            }
            .label {
                text-align: center;
                border-radius:10px;
                &.ex  {  background-color: #75d866; color:#FFF }
                &.ea  { background-color: #2094ff; color:#FFF }
                &.de  { background-color: #062d51; color:#FFF }
                &.ps  { background-color: #ff9020; color:#FFF }
                &.mc  { background-color: #ff9020; color:#FFF }
                &.na  { background-color: #f0f0f0; color:#666 }
            }
        }
        .p-tabview-panels {
            padding: 0 !important;
            border: none !important;
        }
        .p-tabview {
            padding-bottom: 0;
        }
        .p-dropdown-filter-container {
            width: 100%;
        }
        .whatsapp-link {
            margin-left: 8px;
            background-color: #0da64d;
            color: #fff;
            font-weight: 700;
            border-radius: 50%;
            padding: 4px;
            display: inline-block;
            vertical-align: middle;
            width: 28px;
            height: 28px;
            line-height: 24px;
            text-align: center;
            transition: 0.25s;
            &:hover {
                background-color: #0a7d3a;
            }
        }
    }
</style>

<script>
    import Column from 'primevue/column'
    import DataTable from 'primevue/datatable'
    import DataView from 'primevue/dataview'
    import Dialog from 'primevue/dialog'
    import Panel from 'primevue/panel'
    import ProgressBar from 'primevue/progressbar'
    import Tooltip from 'primevue/tooltip'

    import {Agendamento} from './../../middleware'
    import moment from "moment"

    export default {
        components: { Column, DataTable, DataView, Dialog, ProgressBar, Panel },
        directives: { tooltip: Tooltip },
        props: ['display', 'filters'],
        watch: {
            '$parent.selectedRow': function (val) {
                if (this.$parent.dialogLog) {
                    this.model = val
                    this.getList(this.model.id)
                }
            }
        },
        data() {
            return {
                waiting: false,
                list: [],
                options: {
                    ie_status: [
                        { value: 'A', text: 'Agendado' },
                        { value: 'EX', text: 'Executado' }
                    ],
                    ie_status_chamada: [
                        { value: 'MC', text: 'Médico em sala' },
                        { value: 'PS', text: 'Paciente na sala' },
                        { value: null, text: 'Não Iniciada' },
                        { value: "", text: 'Não Iniciada' },
                        { value: 'DE', text: 'Finalizado' },
                        { value: 'EA', text: 'Em Atendimento' },
                        { value: 'PE', text: 'Paciente Recusou' },
                    ],
                },
            }
        },
        methods: {
            getList (id) {
                this.list = []
                this.waiting = true
                Agendamento.findLogTelemedicina(id).then(response => {
                    if (response.status == 200) {

                        let ie_status_obj = {}
                        let ie_status_chamada_obj = {}
                        this.options.ie_status.forEach(e => { if (e.value) ie_status_obj[e.value] = e.text })
                        this.options.ie_status_chamada.forEach(e => { if (e.value) ie_status_chamada_obj[e.value] = e.text })

                        response.data.forEach(entry => {
                            
                            entry.dt_agenda = moment(entry.dt_agenda).format('DD/MM/YYYY')
                            entry.dt_auditoria_f = moment(entry.dt_auditoria).format('DD/MM/YYYY HH:mm')
                            entry.ie_medico_entrou_f = entry.ie_medico_clicou ? 'Entrou' : '-'
                            entry.ie_paciente_entrou_f = entry.ie_paciente_clicou ? 'Entrou' : '-'
                            let aux = entry.hr_agenda.split(':')
                            entry.hr_agenda_f = `${ aux[0] }:${ aux[1] }`
                            entry.ie_status_f = ie_status_obj[entry.ie_status]
                            
                            entry.ie_status_chamada_f = entry.ie_status_chamada ? ie_status_chamada_obj[entry.ie_status_chamada] : 'Não Inciada'
                            if( entry.ie_status_chamada == 'DE' && entry.ie_status == 'EX' )
                                entry.ie_status_chamada_f = 'Finalizado'

                        })
                        this.list = response.data
                    }
                    this.waiting = false
                })
            },

        }
    }
</script>
