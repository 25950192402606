<template lang="pug">
    .main-wrapper.agendamento-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Painel Telemedicina</b>
        
        Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
            .p-grid.p-fluid.p-align-end

                .p-col-12.p-md-4
                    label.form-label Especialista:
                    InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_especialista'
                        @keyup='checkEmptyField("nm_especialista")' )
                
                .p-col-6.p-md-2
                    label.form-label Data da Consulta:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_agenda' dateFormat="dd/mm/yy" :locale="ptbr" :maxDate='filters.dt_fim'
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' @click='filters.dt_agenda = null; applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Nome do paciente:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_paciente'
                            @keyup='checkEmptyField("nm_paciente")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-2
                    label.form-label Situação:
                    Dropdown.input-ieStatus( v-model='filters.ie_status' :options='options.ie_status'
                        dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' @change='applyFilters()' )

                .p-col-12.p-md-2
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')

        LogPainelTelemedicina

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        
        div(v-else)
            DataView.my-2.dataview(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em")
                        Panel.panel-list.ta-center(:header="props.data.nm_paciente.split(' ')[0]")
                            .ta-left
                                p <b>Horário:</b> {{ props.data.hr_agenda_f }}
                                p <b>Nome do paciente:</b> {{ props.data.nm_paciente }}
                                p <b>Especialista:</b> {{ props.data.nm_especialista }}
                                p <b>Situação: </b>
                                    span.mtd-badge(:class="{ co3: props.data.ie_status == 'A', co5: props.data.ie_status == 'EX' }") {{ props.data.ie_status_f }}
                            .ta-right.mt-3
                                Button.p-button-info(label='Visualizar' @click='selectedRow=props.data; dialogVisualizar = true')
            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
            
            Panel.datatable(header='Lista de agendamentos')
                DataTable(:value="list" @sort='onSort')
                    // :sortable='true'
                    Column(headerStyle='width: 3%;' bodyStyle='padding: 0;')
                        template(#body='props')
                            .cell.cursor-default(v-tooltip.top="statusTooltip(props.data.ie_status)" :class="{ \
                                ex: props.data.ie_status == 'EX',\
                                a: props.data.ie_status == 'A', \
                                l: props.data.ie_status == 'L', \
                                c: props.data.ie_status == 'C', \
                                na: props.data.ie_status_chamada == '' || props.data.ie_status_chamada == null, \
                                    }") {{props.data.ie_status}}

                    Column(headerStyle='width: 6%;' bodyStyle='text-align: center;' header='Horário')
                        template(#body='props')
                            p
                                em {{props.data.hr_agenda_f}}
                    Column(headerStyle='width: 15%;' header='Nome do paciente')
                        template(#body='props')
                            p 
                                span {{props.data.nm_paciente}}<br />
                                em(:style={'font-size':'10px'}) Entrou em: {{props.data.hora_paciente_entrou_f}}
                    Column(headerStyle='width: 15%;' field='nm_especialista' header='Especialista')
                        template(#body='props')
                            p 
                                span {{props.data.nm_especialista}}<br />
                                em(:style={'font-size':'10px'}) Entrou em: {{props.data.hora_medico_entrou_f}}

                    Column(headerStyle='width: 12%;' field='nr_telefone_paciente_f' header='Telefone')
                        template(#body='props')
                            p.ta-center(style='margin:0')
                                span(style='display:inline-block;vertical-align:middle') {{ props.data.nr_telefone_paciente_f }}
                                a.whatsapp-link(v-if='props.data.nr_telefone_paciente' :href="`https://api.whatsapp.com/send?phone=${ props.data.nr_telefone_paciente_ws }`" target='_blank')
                                    i(class='jam jam-whatsapp')
                    
                    Column(headerStyle='width: 9%;' header='Chamada')
                        template(#body='props')
                            .label(:class="{ ea: props.data.ie_status_chamada == 'EA', \
                                ps: props.data.ie_status_chamada == 'PS', \
                                mc: props.data.ie_status_chamada == 'MC', \
                                ex: props.data.ie_status_chamada == 'DE' && props.data.ie_status == 'EX', \
                                de: props.data.ie_status_chamada == 'DE', \
                                na: props.data.ie_status_chamada == '' || props.data.ie_status_chamada == null, \
                                }")
                                span {{ props.data.ie_status_chamada_f }}
                            em(v-if="props.data.ie_status_chamada" style="font-size: 10px") {{ props.data.dt_atualizado_f }}
                                
                    Column(headerStyle='width: 5%; text-align: center')
                        template(#header) Ações
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Detalhes'"
                                    icon="jam jam-unordered-list"
                                    @click='selectedRow = props.data; dialogLog = true'
                                )

                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

</template>

<style lang="scss">
    .agendamento-listar {
        .cursor-default { cursor: default; }
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 32px;
                margin: 0 auto;
                text-align:center;
                font-weight:bold;
                color:#FFF;
                &.ex  { background-color: #75d866; }
                &.a  { background-color: #ff9020; }
                &.l  { background-color: #e1f0f8; }
                &.c  { background-color: #f1e1f8; color: #666 }
                &.na  { background-color: #f0f0f0; color:#666 }
            }
            .label {
                text-align: center;
                border-radius:10px;
                &.ex  {  background-color: #75d866; color:#FFF }
                &.ea  { background-color: #2094ff; color:#FFF }
                &.de  { background-color: #062d51; color:#FFF }
                &.ps  { background-color: #ff9020; color:#FFF }
                &.mc  { background-color: #ff9020; color:#FFF }
                &.na  { background-color: #f0f0f0; color:#666 }
            }
        }
        .filter-data {
            .p-inputtext {
                text-align: center;
            }
        }
        .p-tabview-panels {
            padding: 0 !important;
            border: none !important;
        }
        .p-tabview {
            padding-bottom: 0;
        }
        .p-dropdown-filter-container {
            width: 100%;
        }
        .btn-criarAgendamento, .btn-calendario {
            font-size: 16px; font-weight: 700
        }
        .whatsapp-link {
            margin-left: 8px;
            background-color: #0da64d;
            color: #fff;
            font-weight: 700;
            border-radius: 50%;
            padding: 4px;
            display: inline-block;
            vertical-align: middle;
            width: 28px;
            height: 28px;
            line-height: 24px;
            text-align: center;
            transition: 0.25s;
            &:hover {
                background-color: #0a7d3a;
            }
        }
        .input-ieStatus .p-multiselect-header {
            display: none;
        }
    }
</style>

<script>
    import DataView from 'primevue/dataview'
    import Column from 'primevue/column'
    import DataTable from 'primevue/datatable'
    import Paginator from 'primevue/paginator'
    import Panel from 'primevue/panel'
    import Calendar from 'primevue/calendar'
    import ProgressBar from 'primevue/progressbar'
    import InputMask from 'primevue/inputmask'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import InputText from 'primevue/inputtext'
    import Dialog from 'primevue/dialog'
    import TabView from 'primevue/tabview'
    import TabPanel from 'primevue/tabpanel'
    import Tooltip from 'primevue/tooltip'

    import LogPainelTelemedicina from './LogPainelTelemedicina'

    import moment from 'moment'
    import { Agendamento } from './../../middleware'
    import { pCalendarLocale_ptbr, fixTelefoneFormat } from './../../utils'
	import wsConfigs from './../../middleware/configs'
    export default {
        components: { DataView, Panel, ProgressBar, Calendar, InputMask, TabView, Tooltip, Paginator,
            Button, Dropdown, InputText, DataTable, Column, Dialog, TabPanel, LogPainelTelemedicina },
        directives: { tooltip: Tooltip },
        created () {

            let usuario = JSON.parse(localStorage.getItem('usuario'))
            this.usuarioNivel = usuario.nivel
            
            if (!isNaN(this.$route.query.pg)) this.paginator.page = this.$route.query.pg

            this.waiting = true
            this.applyFilters()

            this.interval = setInterval(() => {this.applyFilters(false)}, 10000)
        },
        beforeDestroy(){
            clearInterval(this.interval);
        },
        data () {
            return {
                interval:false,
                list: [],
                usuarioNivel:1,
                ptbr: pCalendarLocale_ptbr,
                windowInnerWidth: window.innerWidth,
                waiting: false,
                selectedRow: {},
                dialogLog:false,
                filters: {
                    dt_agenda: moment()._d,
                    ie_status: null,
                    nm_paciente: null,
                    nm_especialista: null,
                },
                options: {
                    ie_status: [
                        { value: 'A', text: 'Agendado' },
                        { value: 'EX', text: 'Executado' },
                        { value: 'C', text: 'Cancelado' }
                    ],
                    ie_status_chamada: [
                        { value: 'MC', text: 'Médico em sala' },
                        { value: 'PS', text: 'Paciente na sala' },
                        { value: null, text: 'Não Iniciada' },
                        { value: "", text: 'Não Iniciada' },
                        { value: 'DE', text: 'Desligada' },
                        { value: 'EA', text: 'Em Atendimento' },
                        { value: 'PE', text: 'Paciente Recusou' },
                    ],
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                order: {
                    field: 'dt_agenda',
                    sortOrder: 1
                }
            }
        },
        methods: {
            statusTooltip (ie_status) {
                let find = this.options.ie_status.find(s => s.value === ie_status)
                return find ? find.text : 'Status'
            },
            applyFilters (loading = true) {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                params['loading'] = loading

                params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`

                if (this.$route.query.tb != this.filters.ie_tipo_agenda || this.$route.query.pg != this.paginator.page)
                    this.$router.replace( { query: { tb: this.filters.ie_tipo_agenda, pg: this.paginator.page } } )

                Object.keys(this.filters).forEach((key) => {
                    if (key === 'ie_unidade') {
                        if (this.filters[key] !== null)
                            params[key] = this.filters[key]
                    } else if (this.filters[key]) {
                        if (key.substr(0, 3) == 'dt_')
                            params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
                        else if (key === 'ie_status') {
                            params[key] = this.filters[key].toString()
                        } else params[key] = this.filters[key]
                    }
                })

                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            onSort () {
                this.order.sortOrder = -this.order.sortOrder
                this.applyFilters()
            },
            checkEmptyField (field) {
                if (! this.filters[field].length) this.applyFilters()
            },
            clearFilters () {
                Object.keys(this.filters).forEach(k => {
                    if (k != 'dt_agenda') this.filters[k] = moment()._d
                })
                this.applyFilters()
            },
            getList (params) {
                if( params['loading'] ){
                    this.waiting = true
                    this.list = []
                }
                params['order'] = 'hr_agenda'
                
                
                Agendamento.findAllTelemedicina(params).then(response => {
                    if (response.status == 200) {
                        this.list = []
                        let ie_status_obj = {}
                        let ie_status_chamada_obj = {}
                        this.options.ie_status.forEach(e => { if (e.value) ie_status_obj[e.value] = e.text })
                        this.options.ie_status_chamada.forEach(e => { if (e.value) ie_status_chamada_obj[e.value] = e.text })
                        this.paginator.count = response.data.count

                        response.data.results.forEach(entry => {
                            entry.dt_agenda = moment(entry.dt_agenda).format('DD/MM/YYYY')
                            entry.dt_atualizado_f = moment(entry.dt_atualizado).format('DD/MM/YYYY HH:mm')
                            entry.dt_atualizado_f2 = moment(entry.dt_atualizado).format('HH:mm')
                            entry.hora_medico_entrou_f = entry.hora_medico_entrou ? moment(entry.hora_medico_entrou).format('DD/MM/YYYY HH:mm') : 'Não compareceu'
                            entry.hora_paciente_entrou_f = entry.hora_paciente_entrou ? moment(entry.hora_paciente_entrou).format('DD/MM/YYYY HH:mm') : 'Não compareceu'
                            let aux = entry.hr_agenda.split(':')
                            entry.hr_agenda_f = `${ aux[0] }:${ aux[1] }`
                            entry.ie_status_f = ie_status_obj[entry.ie_status]
                            
                            entry.ie_status_chamada_f = entry.ie_status_chamada ? ie_status_chamada_obj[entry.ie_status_chamada] : 'Não Inciada'
                            if( entry.ie_status_chamada == 'DE' && entry.ie_status == 'EX' )
                                entry.ie_status_chamada_f = 'Finalizada (✓)'

                            if (entry.nr_telefone_paciente) {
                                entry.nr_telefone_paciente_f = fixTelefoneFormat(entry.nr_telefone_paciente)
                                entry.nr_telefone_paciente_ws = entry.nr_telefone_paciente.match(/\d+/g).join('')
                                if (entry.nr_telefone_paciente_ws.substr(0, 2) === '55') {
                                    entry.nr_telefone_paciente_ws = `${ entry.nr_telefone_paciente_ws }`
                                } else {
                                    entry.nr_telefone_paciente_ws = `55${ entry.nr_telefone_paciente_ws }`
                                }
                            }
                        })
                        this.list = response.data.results
                    }
                    this.waiting = false
                })
            },
            nextDay () { this.filters.dt_agenda = moment(this.filters.dt_agenda).add(1, 'days')._d },
            prevDay () { this.filters.dt_agenda = moment(this.filters.dt_agenda).subtract(1, 'days')._d }
        }
    }
</script>